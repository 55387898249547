<template>
  <v-container dense>
    <v-dialog v-model="dialogStatus" :modal="true" width="500px">
      <v-card :loading="loading">
        <v-card-title class="grey lighten-2"
          >FinanzOnline UID Check</v-card-title
        >
        <v-card-text>
          <MandantenSelectbox
            v-model="mandant"
            :showall="false"
            :clearable="false"
            :v-model="mandant"
          />
          <v-btn :disabled="!mandant" color="purple" @click="checkUID()"
            >UID prüfen</v-btn
          >

          <v-alert
            style="margin-top:10px;"
            :color="alertColor"
            v-if="resultMessage"
            >{{ resultMessage }}</v-alert
          >
          <v-list disabled>
            <v-list-item-group>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon
                    v-if="!checkLoading && resultMessage"
                    v-text="checkicon"
                  ></v-icon>
                  <v-progress-circular
                    v-if="checkLoading"
                    indeterminate
                    color="purple"
                  ></v-progress-circular>
                </v-list-item-icon>
                <v-list-item-content
                  >FinanzOnline UID Abfrage</v-list-item-content
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog">{{ buttonText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DataService from "@/services/UIDCheckService";
import MandantenSelectbox from "@/components/clients/MandantenSelectbox";

export default {
  name: "uidCheckDialog",
  components: { MandantenSelectbox },
  data: () => ({
    buttonText: "Abbrechen",
    loading: false,
    checkLoading: false,
    checkicon: "mdi-check",
    resultMessage: "",
    alertColor: "error",
    mandant: null,

    fieldLabels: null
  }),
  props: ["kdnr", "uid", "value"],
  methods: {
    closeDialog() {
      this.buttonText = "Schließen";
      this.loading = false;
      this.checkLoading = false;
      this.dialogStatus = false;
      this.checkicon = "mdi-check";
      this.resultMessage = "";
      this.alertColor = "error";
    },
    checkUID() {
      this.loading = true;
      this.checkLoading = true;
      DataService.check(this.kdnr, this.uid, this.mandant)
        .then((result) => {
          //check
          if (result.data.data.rc === 0) {
            this.resultMessage = "UID Nr ist Gültig";
            this.alertColor = "success";
            this.$notify({
              group: "dataline",
              type: "success",
              title: "UID Check",
              text: "UID Check war erfolgreich"
            });
          } else {
            if (result.data.data.msg) {
              this.resultMessage = result.data.data.msg;
            } else {
              this.resultMessage = result.data.data.error;
            }

            this.alertColor = "error";
            this.$notify({
              group: "dataline",
              type: "error",
              title: "UID Check",
              text: this.resultMessage
            });
          }
        })
        .catch((error) => {
          this.resultMessage = error.response.data.error;
          this.alertColor = "error";

          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "UID Check Fehler",
              text: "FinanzOnline Abfrage fehlgeschlagen: " + this.resultMessage
            });
          }
        })
        .finally(() => {
          this.checkLoading = false;
          this.loading = false;
          this.buttonText = "Schließen";
        });
    }
  },
  computed: {
    dialogStatus: {
      get() {
        if (this.value == true) {
          //this.checkUID();
        }
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  created(){

    if(this.$store.getters["user/getUser"].apps.rights.length == 1){
      this.mandant = this.$store.getters["mandant/getWorkingMandant"];
    }

  },
  mounted() {}
};
</script>

<style scoped>
.importTable {
  padding: 4px;
}
</style>
