<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card dense>
          <v-toolbar color="deep-purple lighten-2" dark flat>
            <v-toolbar-title>Kontakt bearbeiten</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <KontaktForm />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @ is an alias to /src
import KontaktForm from "@/components/contacts/KontaktForm";

export default {
  name: "KontaktEdit",
  components: { KontaktForm },
  data: () => ({}),

  methods: {},
  computed: {}
};
</script>
<style scoped></style>
