<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :value="value"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formatDate"
        :label="label"
        prepend-icon="mdi-calendar"
        clearable
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
      <v-btn text color="primary" @click="updateValue(date)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
export default {
  name: "MenuDatePicker",
  props: ["label", "value"],
  data: () => ({
    menu: null,
    date: null
  }),
  methods: {
    updateValue(value) {
      this.date = value;
      this.$refs.menu.save(value);
      this.$emit("input", value);
    }
  },
  computed: {
    formatDate: {
      get() {
        return this.date ? moment(this.date).format("DD.MM.YYYY") : "";
      },
      set(value) {
        // this.updateValue(value);
        this.date = value;
        this.$refs.menu.save(value);
      }
    }
  },
  watch: {
    value(val) {
      // this.updateValue(val);v
      this.date = val;
      this.$refs.menu.save(val);
    }
  },
  created() {
    //this.date = moment().format("YYYY-MM-DD");
  }
};
</script>
