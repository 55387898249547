import DataService from "@/services/DataService";

export default {
  check(kdnr, uid, mandant) {
    return DataService.apiClient.post("/fon/check/", { kdnr, uid, mandant });
  },
  findCheck(uid) {
    return DataService.apiClient.get("/fon/findCheck/" + uid);
  }
};
