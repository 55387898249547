<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" xs="12">
        <v-card dense>
          <v-card-text>
            <v-form
              name="contactform"
              @submit.prevent="save"
              v-model="formValid"
            >
              <v-container>
                <h2>Kontaktdetails</h2>

                <v-row>
                  <v-col cols="12" xs="12" sm="4">
                    <v-text-field
                      label="Kdnr"
                      name="kdnr"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.kdnr"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="4">
                    <v-select
                      label="Anrede"
                      name="anrede"
                      :items="anredeItems"
                      color="purple"
                      :clearable="true"
                      v-model="contact.anrede"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="4">
                    <v-text-field
                      label="Titel"
                      name="titel"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.titel"
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Name1"
                      name="name1"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.name1"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>

                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Name2"
                      name="name2"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.name2"
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12">
                    <ClubSelectbox v-model="club" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12">
                    <v-text-field
                      label="Adresse"
                      name="adresse"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.adresse"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="4">
                    <v-select
                      label="Land"
                      name="land"
                      :items="countryList"
                      item-text="nameDE"
                      item-value="code"
                      color="purple"
                      :clearable="true"
                      v-model="contact.land"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="2">
                    <v-text-field
                      label="PLZ"
                      name="plz"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.plz"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Ort"
                      name="ort"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.ort"
                      required
                      dense
                      :rules="requiredRule"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Email"
                      name="email"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.email"
                      dense
                      :rules="emailRules"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Web"
                      name="web"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.web"
                      dense
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Telefon"
                      name="telefon"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.telefon"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="6">
                    <v-text-field
                      label="Fax"
                      name="fax"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.fax"
                      dense
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <h2>Zusatzinformationen</h2>
                <v-row v-if="uidCheckExists">
                  <v-alert border="left" colored-border color="purple">
                    Letzter UID Check:
                    <strong>{{ lastUIDCheckDate }}</strong> von
                    <strong>{{ lastUIDCheck.createdBy }}</strong
                    >. Ergebnis:
                    <v-chip :color="lastUIDCheckColor">{{
                      lastUIDCheck.result
                    }}</v-chip>
                  </v-alert>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="UID"
                      name="uid"
                      type="text"
                      color="purple"
                      :clearable="true"
                      v-model="contact.uid"
                      append-icon="mdi-bank-transfer"
                      @click:append="checkuid"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      label="Zahlungsziel in Tagen"
                      name="zahlungstage"
                      suffix="Tage"
                      type="number"
                      color="purple"
                      min="8"
                      max="365"
                      v-model="contact.zahlungstage"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-combobox
                      label="Zahlungskondition"
                      name="zahlungskondition"
                      :items="zkItems"
                      color="purple"
                      :clearable="true"
                      v-model="contact.zahlungskondition"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <MandantenSelectbox
                      color="purple"
                      v-model="contact.mandant"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-switch
                      label="DSGVO Einwilligung vorhanden"
                      name="dsgvo"
                      type="text"
                      color="green"
                      v-model="contact.dsgvo"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-switch
                      label="Newsletter"
                      name="newsletter"
                      type="text"
                      color="green"
                      v-model="contact.newsletter"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <MenuDatePicker
                      v-model="contact.newsletter_signin"
                      name="newsletter_signin"
                      label="Anmelde Datum"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <MenuDatePicker
                      v-model="contact.newsletter_signout"
                      name="newsletter_signout"
                      label="Abmelde Datum"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      label="Anmerkungen"
                      name="anmerkungen"
                      color="purple"
                      :outlined="true"
                      :clearable="true"
                      v-model="contact.anmerkungen"
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-divider />
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :to="{ path: '/kontakte' }"
              color="error"
              type="button"
              name="cancel"
              >Abbrechen</v-btn
            >
            <v-spacer />
            <v-btn
              @click="save"
              color="success"
              name="submit"
              type="submit"
              :disabled="!formValid"
              >Speichern</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <UIDCheckDialog
      v-model="dlgUIDCheck"
      :uid="contact.uid"
      :kdnr="contact.kdnr"
    />
    <v-dialog v-model="errorDialog" max-width="290">
      <v-card>
        <v-card-title color="error">Fehler</v-card-title>

        <v-card-text>
          Das Feld für die UID Nummer muss ausgefüllt und min. 6 Zeichen
          enthalten um die UID Prüfung durchzuführen.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="errorDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DataService from "@/services/ContactService.js";
import UIDCheckService from "@/services/UIDCheckService.js";
import MandantenSelectbox from "@/components/clients/MandantenSelectbox";
import ClubSelectbox from "@/components/contacts/ClubSelectbox";
import UIDCheckDialog from "@/components/UIDCheckDialog";
import MenuDatePicker from "@/components/MenuDatePicker";
import moment from "moment";

export default {
  name: "contactform",
  components: {
    MandantenSelectbox,
    UIDCheckDialog,
    MenuDatePicker,
    ClubSelectbox,
  },
  data: () => ({
    uidCheckExists: false,
    lastUIDCheck: null,
    dlgUIDCheck: false,
    errorDialog: false,
    anredeItems: [
      "Herr",
      "Frau",
      "Familie",
      "Firma",
      "Verein",
      "Mr.",
      "Mrs.",
      "Club",
    ],

    zkItems: ["netto", "2% Skonto", "3% Skonto", "Vorauskasse"],
    club: null,
    contact: {
      _id: null,
      kdnr: "",
      anrede: "",
      titel: "",
      name1: "",
      name2: "",
      name3: "",
      club: "",
      adresse: "",
      plz: "",
      ort: "",
      land: "",
      email: "",
      web: "",
      telefon: "",
      fax: "",
      uid: "",
      contactType: "",
      lieferantennr: "",
      mandant: "all",
      dsgvo: false,
      newsletter: false,
      newsletter_signin: null,
      newsletter_signout: null,
      zahlungstage: 14,
      zahlungskondition: "netto",
      anmerkungen: "",

      createdBy: "",
      createdTS: "",
      modifiedBy: "",
      modifiedTS: "",
    },
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) =>
        /^$/.test(v) ||
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail Format ungültig",
    ],
    formValid: true,
  }),

  methods: {
    checkuid() {
      if (this.contact.uid.length > 6) {
        this.dlgUIDCheck = true;
      } else {
        //Fehler Message
        this.errorDialog = true;
        this.$notify({
          group: "dataline",
          type: "error",
          title: "UID Check",
          text: "Für die Überprüfung der UID Nr muss das Feld ausgefüllt werden.",
        });
      }
    },
    save() {
      if (this.club) {
        this.contact.club = this.club._id;
      }
      DataService.save({
        id: this.contact._id,
        data: this.contact,
      })
        .then(() => {
          //if success redirecht to list
          this.$router.push({ name: "kontakte" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Kontakt gespeichert",
            text: "Kontakt erfolgreich gespeichert",
          });
        })
        .catch((error) => {
          //on error alert error
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        });
    },
  },
  computed: {
    countryList() {
      return this.$store.getters["countries/getCountryList"];
    },
    lastUIDCheckDate() {
      if (this.lastUIDCheck && this.lastUIDCheck.createdTS) {
        return moment(this.lastUIDCheck.createdTS).format("DD.MM.YYYY H:m:s");
      } else {
        return "";
      }
    },
    lastUIDCheckColor() {
      if (this.lastUIDCheck.result == "success") {
        return "success";
      } else {
        return "error";
      }
    },

    mandantenList: {
      get() {
        let mandanten = [{ text: "Alle", value: "all" }];
        const mList = this.$store.getters["mandant/getMandantenList"];
        mList.forEach((item) => {
          mandanten.push({ text: item.firmenname, value: item._id });
        });
        return mandanten;
      },
      set() {},
    },

    Contact: {
      get() {
        return this.contact;
      },
      set(m) {
        this.contact = m;
      },
    },
  },
  created() {},
  beforeCreate() {
    let id = this.$route.params.id;

    if (id) {
      DataService.get(id)
        .then((item) => {
          this.contact = item.data.data;
          if (item.data.club) {
            this.club = item.data.club;
          }

          //find last uid check
          if (this.contact.uid) {
            UIDCheckService.findCheck(this.contact.uid)
              .then((result) => {
                this.lastUIDCheck = result.data.data;
                if (this.lastUIDCheck != null) {
                  this.uidCheckExists = true;
                }
              })
              .catch((error) => {
                if (error.response.status != 401) {
                  this.$notify({
                    group: "dataline",
                    type: "error",
                    title: "Fehler bei UID Check",
                    text: "Der letzte UID Check aus den Logs konnte nicht abgefragt werden.",
                  });
                }
              });
          }
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message,
            });
          }
        });
    } else {
      this.$store.dispatch("contact/getLastID").then((result) => {
        if (result) {
          let lastKdnr = Number.parseInt(result);
          lastKdnr++;
          this.contact.kdnr = lastKdnr;
        }
      });
    }
  },
};
</script>
